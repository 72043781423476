import Vue from "vue";
import Vuetify from "vuetify/lib";
//import "vuetify/dist/vuetify.min.css";
//import "vuetify/src/styles/main.sass";
//import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    themes: {
      light: {
        primary: "#F46524",
        secondary: '#008AFF', // <-- new
        accent: '#005398',
        green: '#00B46E',
        gradient: '#FFC107'
      },
      dark: {
        primary: "#F46524",
        secondary: '#24b2f4',
        accent: '#005398',
        green: '#00B46E',
        gradient: '#FFC107'
      }
    },
    options: {
      customProperties: true // generate css variables
    },
  }
});
