import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { sync } from 'vuex-router-sync'
import vuetify from "./plugins/vuetify";
import helpers from "./helpers.js";
import services from "./services";
import VueGtag from "vue-gtag";
import VueScrollTo from "vue-scrollto";

Vue.config.productionTip = false;

const plugin = {
  install(Vue) {
    Vue.prototype.$helpers = helpers; // we use $ because it's the Vue convention
  }
};

const unsync = sync(store, router) // done. Returns an unsync callback fn

// note: this is serious performance penalty. Around 10-20 lighthouse points!
Vue.use(VueGtag, {
  config: { id: "UA-158047576-1" },
  appName: 'VeganFoodValues',
  enabled: true,
}, router);

Vue.use(VueScrollTo, {
  duration: 500,
  easing: "ease",
  offset: -100,// headers and sticky tabs
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    services.enableInterceptor();
  },
  data: {
  },
  computed: {
    isLoading() {
      return services.isLoading;
    }
  }
}).$mount("#app");

Vue.use(plugin);
//Vue.use(require('vue-moment'));

Vue.filter('striphtml', function (value) {
  if (!value) {
    return value;
  }
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

Vue.filter('lowercase', function (value) {
  if (!value) {
    return value;
  }
  return value.toLowerCase();
});

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
      value = value.substring(0, (limit - 3)) + '...';
  }

  return value
});

Vue.filter('duration', function (value, limit) {
  var iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

  var parseISO8601Duration = function (iso8601Duration) {
    var matches = iso8601Duration.match(iso8601DurationRegex);

    return {
        sign: matches[1] === undefined ? '+' : '-',
        years: matches[2] === undefined ? 0 : matches[2],
        months: matches[3] === undefined ? 0 : matches[3],
        weeks: matches[4] === undefined ? 0 : matches[4],
        days: matches[5] === undefined ? 0 : matches[5],
        hours: matches[6] === undefined ? 0 : matches[6],
        minutes: matches[7] === undefined ? 0 : matches[7],
        seconds: matches[8] === undefined ? 0 : matches[8]
    };
  };
  var parsed = parseISO8601Duration(value);
  var parts = [];
  if (parsed.hours > 0) {
      parts.push(parsed.hours + ' h');
  }
  if (parsed.minutes > 0) {
      parts.push(parsed.minutes + ' min');
  }
  if (parts.length > 0) {
      return parts.join(' ');
  }
  return null;
});
