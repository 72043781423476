import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: 0,
    skipNext: false
  },
  getters: {
    loading(state) {
      return state.loading;
    }
  },
  mutations: {
    START_LOADING(state) {
      // mutate state
      if (!state.skipNext) {
        state.loading++;
      }
    },
    IGNORE_LOADING(state) {
      // mutate state
      state.skipNext = true;
    },
    STOP_LOADING(state) {
      // mutate state
      if (!state.skipNext) {
        state.loading--;
      }
      state.skipNext = false;
    }
  }
})
