import axios from "axios";
import httpStore from "@/store/http-store";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_HOST
});

export default {
    http: http,
    enableInterceptor() {
        this.axiosInterceptor = http.interceptors.request.use((config) => {
          httpStore.commit('START_LOADING');
        return config;
      }, (error) => {
        httpStore.commit('STOP_LOADING');
        return Promise.reject(error);
      });

      http.interceptors.response.use((response) => {
        httpStore.commit('STOP_LOADING');
        return response;
      }, function (error) {
        httpStore.commit('STOP_LOADING');
        return Promise.reject(error);
      });
    },

    disableInterceptor() {
      http.interceptors.request.eject(this.axiosInterceptor);
    }
};
