export default {
    getIngredientColor(ingredient) {
        if (ingredient.weighted)
            return 'green';
            if (ingredient.foodId)
            return 'light-green';
            if (ingredient.parsed)
            return 'orange';
        return 'red';
    }
}
