<template>
    <!-- <div>
        <div class="share-dialog">
            <header>
                <h3 class="dialog-title">Share this pen</h3>
                <button class="close-button"><svg><use href="#close"></use></svg></button>
            </header>
            <div class="targets">
                <a class="button">
                <svg>
                    <use href="#facebook"></use>
                </svg>
                <span>Facebook</span>
                </a>
                
                <a class="button">
                <svg>
                    <use href="#twitter"></use>
                </svg>
                <span>Twitter</span>
                </a>
                
                <a class="button">
                <svg>
                    <use href="#linkedin"></use>
                </svg>
                <span>LinkedIn</span>
                </a>
                
                <a class="button">
                <svg>
                    <use href="#email"></use>
                </svg>
                <span>Email</span>
                </a>
            </div>
            <div class="link">
                <div class="pen-url">https://codepen.io/ayoisaiah/pen/YbNazJ</div>
                <button class="copy-link">Copy Link</button>
            </div>
        </div>

        <button class="share-button" type="button" title="Share this article" @click="showShareOptions">
            <span>Share</span>
        </button>
    </div> -->
    <v-btn v-if="nativeShare" icon class="" aria-label="Share this page" title="Share this page" @click="showShareOptions"><v-icon>fa-share-alt</v-icon></v-btn>
    <v-dialog v-else width="310">
        <template v-slot:activator="{ on }">
            <v-btn icon class="" aria-label="Share this page" title="Share this page" v-on="on"><v-icon>fa-share-alt</v-icon></v-btn>
        </template>
        <v-list>
            <v-subheader class="title">Share this page</v-subheader>
            <!-- <v-list-item :href="twitterUrl" target="_blank">
                <v-list-item-avatar><v-avatar size="32px" tile>
                    <v-icon color="#55acee" alt="Twitter">fab fa-twitter</v-icon>
                </v-avatar></v-list-item-avatar>
                <v-list-item-title>Twitter</v-list-item-title>
            </v-list-item> -->
            <v-list-item
                v-for="sharer in sharers"
                :key="sharer.title"
                @click="sheet = false"
                :href="sharer.url"
                target="_blank"
                >
                <v-list-item-avatar>
                    <v-avatar size="32px" tile>
                        <v-icon :color="sharer.color" :alt="sharer.title">{{ sharer.icon }}</v-icon>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>{{ sharer.title }}</v-list-item-title>
            </v-list-item>
        </v-list>            
    </v-dialog>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "ShareButton",
  props: {
  },
  data() {
    return {
      nativeShare: false /*navigator.share*/,
      pageTitle: null,
      pageDescription: null,
      pageUrl: null, 
      sharers: null,
    };
  },
  mounted() {
    // load initial state
    this.updatePageState(this.$store.state.currentPageMeta);
    // subscribe to nav updates
    EventBus.$on('page-header-change', this.updatePageState);
  },
  watch:{
    $route (to, from){
        // get nav updates from child component
        this.pageUrl = this.getPageUrl();
        this.sharers = this.createSharers();
    }
  },
  computed: {
    twitterUrl() {
        return 'https://twitter.com/intent/tweet?url='+encodeURIComponent(this.getPageUrl())+'&text='+encodeURIComponent(this.pageTitle)+'&hashtags=veganfoodvalues'
    },
  },
  methods: {
    createSharers() {
        return [
          //{ color: '#c32aa3', icon: 'fab fa-instagram', title: 'Instagram', url: this.getInstagamUrl() },
          { color: '#3b5998', icon: 'fab fa-facebook', title: 'Facebook', url: this.getFacebookUrl() },
          { color: '#55acee', icon: 'fab fa-twitter', title: 'Twitter', url: this.getTwitterUrl() },
          { color: '#25D366', icon: 'fab fa-whatsapp', title: 'Whatsapp', url: this.getWhatsappUrl() },
          { color: '#0077b5', icon: 'fab fa-linkedin', title: 'LinkedIn', url: this.getLinkedInUrl() },
          { color: '', icon: 'fas fa-envelope', title: 'Email', url: this.getEmailUrl() },
      ];
    },
    updatePageState(data) {
        console.log('Loading new data to share', data);
        this.pageTitle = data.title;
        this.pageDescription = data.description;
        this.sharers = this.createSharers();
    },
    getPageUrl() {
        return document.querySelector('link[rel=canonical]') ? document.querySelector('link[rel=canonical]').href : document.location.href;
    },
    showNativeShareOptions() {
        if (navigator.share) { 
            navigator.share({
                title: this.pageTitle,
                url: this.pageUrl,
            }).then(() => {
                //console.log('Thanks for sharing!');
            })
            .catch(console.error);
        } else {
            console.log('Native share not available');
            //shareDialog.classList.add('is-open');
        }
    },
    getTwitterUrl() {
        return 'https://twitter.com/intent/tweet?url='+encodeURIComponent(this.getPageUrl())+'&text='+encodeURIComponent(this.pageTitle)+'&hashtags=veganfoodvalues'
    },
    getLinkedInUrl() {
        return 'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(this.getPageUrl())+'&source=veganfoodvalues'
    },
    getFacebookUrl() {
        return 'https://www.facebook.com/sharer.php?u='+encodeURIComponent(this.getPageUrl())
    },
    getEmailUrl() {
        return 'mailto:?subject='+encodeURIComponent(this.pageTitle)+ '&body='+encodeURIComponent(this.getPageUrl());
    },
    getWhatsappUrl() {
        return 'whatsapp://send?text='+encodeURIComponent(this.pageTitle)+ '%20'+ encodeURIComponent(this.getPageUrl());
    },
    getInstagamUrl() {
        return null;
    },
    socialWindow(url) {
        var left = (screen.width - 570) / 2;
        var top = (screen.height - 570) / 2;
        var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
        window.open(url,"NewWindow",params);
    }

  },
};
</script>

<style scoped>

.share-button, .share-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  display: none;
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

.share-dialog.is-open {
  display: block;
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>