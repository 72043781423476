import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
import store from "@/store";
import { EventBus } from '@/plugins/eventbus.js';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      noLayout: true,
      title: 'VeganFoodValues',
      description: 'Discover healthy vegan recipes using our powerful plant-based recipe search engine with a focus on their nutritional content.',
      toolbarClass: 'transparent'
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'About us',
      description: 'Background on who we are and why we started with VeganFoodValues.com.',
      illustration: 'about_veganfoodvalues.svg',
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Contact Us',  
      description: 'Contact us and let us know your thoughts. Use our contact form to send your comments and feedback. We love to hear from you!',
      illustration: 'contact_us.svg',
    }
  },
  {
    path: "/approach",
    name: "approach",
    component: () => import("../views/Approach.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Our Approach',
      description: 'Our mission i to ancourage a vegan lifestyle by providing complete information on recipes, ingredients and nutrients.',
      illustration: 'approach_vegan_recipe_analysis.svg',
    }
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Privacy Policy',
      description: 'We respect your privacy',
      illustration: 'privacypolicy.svg',
    }
  },
  {
    path: "/cookiepolicy",
    name: "cookiepolicy",
    component: () => import("../views/CookiePolicy.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Cookie Policy',
      description: 'We use cookies to help improve your experience of VeganFoodValues',
      illustration: 'cookiepolicy.svg',
    }
  },
  {
    path: "/termsofservice",
    name: "termsofservice",
    component: () => import("../views/TermsOfService.vue"),
    meta: {
      contentLayout: true,
      toolbarClass: 'transparent',
      title: 'Terms of Service',
      description: '',
      illustration: 'termsofservice.svg',
    }
  },
  {
    path: "/recipes",
    name: "recipes",
    component: () => import("../views/Recipes.vue"),
    meta: {
      title: 'Vegan Recipes',
    }
  },
  {
    path: "/recipes/wizard",
    name: "recipesWizard",
    component: () => import("../views/RecipeWizard.vue"),
    meta: {
      title: 'Vegan Recipe Wizard',
    }
  },
  {
    path: "/recipes/:id",
    name: "recipe",
    component: () => import("../views/Recipe.vue")
  },
  {
    path: "/ingredients",
    name: "ingredients",
    component: () => import("../views/Ingredients.vue")
  },
  {
    path: "/ingredients/categories/:id",
    name: "ingredientCategory",
    component: () => import("../views/IngredientCategory.vue")
  },
  {
    path: "/ingredients/:id",
    name: "ingredient",
    component: () => import("../views/Ingredient.vue")
  },
  {
    path: "/nutrients",
    name: "nutrients",
    component: () => import("../views/Nutrients.vue")
  },
  {
    path: "/nutrients/categories/:id",
    name: "nutrientCategory",
    component: () => import("../views/NutrientCategory.vue")
  },
  {
    path: "/nutrients/:id",
    name: "nutrient",
    component: () => import("../views/Nutrient.vue")
  },
  {
    path: "/articles/:id",
    name: "article",
    component: () => import("../views/Article.vue"),
    meta: {
    }
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/Blog.vue"),
    meta: {
    }
  },
  {
    path: "/blog/:id",
    name: "blogPost",
    component: () => import("../views/Article.vue"),
    meta: {
      type: "blog"
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

if (process.env.NODE_ENV === 'development') {
  router.addRoutes([
    {
      path: "/admin/recipes",
      name: "admin-recipes",
      component: () => import("../views/AdminRecipes.vue")
    },
    {
      path: "/admin/source",
      name: "admin-source",
      component: () => import("../views/AdminSource.vue")
    },
    {
      path: "/admin/nutrients/:id",
      name: "admin-nutrient",
      component: () => import("../views/AdminNutrient.vue")
    },
    {
      path: "/admin/ingredients",
      name: "admin-ingredients",
      component: () => import("../views/AdminIngredients.vue")
    },
    {
      path: "/admin/ingredients/:id",
      name: "admin-ingredient",
      component: () => import("../views/AdminIngredient.vue")
    },
    {
      path: "/admin/recipes/:id",
      name: "admin-recipe",
      component: () => import("../views/AdminRecipe.vue")
    },
    {
      path: "/admin/mappings",
      name: "admin-mappings",
      component: () => import("../views/AdminMappings.vue")
    }
  ]);
}
router.addRoutes([{ path: '*', name: '404', component: () => import("../views/NotFound.vue") }]);

function createMetaTag(name, value) {
  const tag = document.createElement('meta');
  tag.setAttribute('name', name);
  tag.setAttribute('content', value);
  tag.setAttribute('data-vue-router-controlled', '');
  return tag;
}

function createSchemaTag(value) {
  const tag = document.createElement('script');
  tag.setAttribute('type', 'application/ld+json');
  tag.setAttribute('data-vue-router-controlled', '');
  tag.innerHTML = value;
  return tag;
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  var title = nearestWithTitle ? nearestWithTitle.meta.title : 'VeganFoodValues';

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  var metaTags = nearestWithMeta ? nearestWithMeta.meta.metaTags : [];

  // Turn the meta tag definitions into actual elements in the head.
  var tags = metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  });
  var description = null;
  if (nearestWithDescription) {
    description = nearestWithDescription.meta.description;
  }
  // Add the meta tags to the document head.
  tags.forEach(tag => document.head.appendChild(tag));

  var meta = {
    title: title,
    description: description,
    //image: this.recipe.imgUrl,
  };
  EventBus.$emit('page-header-change', meta);

  // store it for components to use
  store.commit('updatePageMeta', meta);
  next();
});

router.afterEach((to, from) => {
  store.commit('updateSearchExpanded', false);
})

EventBus.$on('page-schema-change', data => {
  Array.from(document.querySelectorAll('[data-vue-router-controlled][type="application/ld+json"]')).map(el => el.parentNode.removeChild(el));
  document.head.appendChild(createSchemaTag(JSON.stringify(data)))
});

EventBus.$on('page-header-change', data => {
  document.title = data.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled][name="description"]')).map(el => el.parentNode.removeChild(el));
  Array.from(document.querySelectorAll('[data-vue-router-controlled][name="og:description"]')).map(el => el.parentNode.removeChild(el));
  if (data.description) {
    document.head.appendChild(createMetaTag('description', data.description))
    document.head.appendChild(createMetaTag('og:description', data.description))
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled][name="og:image"]')).map(el => el.parentNode.removeChild(el));
  if (data.image) {
    document.head.appendChild(createMetaTag('og:image', data.image))
  }
});

export default router;
