import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import sourcesInfo from '@/data/sources.json'
//import homepage from '@/data/homepage.json'
import nav from '@/data/nav.json'
import search from '@/data/search.json'

Vue.use(Vuex);

const filterStore = {
  state: {
    query: null,
    foodIds: null,
    allergies: null,
    nutrientId: null,
    oilFree: false,
    sugarFree: false,
  },
  getters: {
    hasSearch: (state, getters) => {
      return state.query || getters.hasFilter;
    },
    hasFilter: state => {
      return (state.foodIds && state.foodIds.length > 0) || (state.allergies && state.allergies.length > 0) || state.nutrientId;
    },
    searchPostData: state => {
      return {
        q: state.query,
        nutrientId: state.nutrientId,
        foodId: state.foodIds,
        allergy: [state.sugarFree ? 'sugar-free' : null, state.oilFree ? 'oil-free' : null, ...(state.allergies ? state.allergies : [])],
        limit: 25,
      }
    },
  },
  mutations: {
    storeQuery(state, payload) {
      console.log('Setting search state to:', payload);
      state.query = payload.query;
      state.foodIds = payload.foodIds;
      state.allergies = payload.allergies;
      state.nutrientId = payload.nutrientId;
      state.sugarFree = payload.sugarFree;
      state.oilFree = payload.oilFree;
    },
  },
  actions: {
    loadfromUrl({commit, state, rootState}) {
      var filter = {
        query: rootState.route.query['query'],
        foodIds: rootState.route.query['foodId'],
        nutrientId: rootState.route.query['nutrientId'],
        allergies: rootState.route.query['allergy'],
        sugarFree: rootState.route.query['sugarFree'],
        oilFree: rootState.route.query['oilFree'],
      };
      if (Object.values(filter).some(x => (x !== null && x !== undefined && x !== ''))) {
        console.log('Updating state from url:', filter);
        commit('storeQuery', filter);
      }
    },
    storeInUrl({commit, state, rootState}) {
      var data = {
        query: state.query,
        allergy: state.allergies,
        nutrientId: state.nutrientId,
        foodId: state.foodIds,
        sugarFree: state.sugarFree,
        oilFree: state.oilFree,
      };
      // Only store non-null objects in url to keep things nice 'n clean
      Object.keys(data).forEach((key) => (data[key] == null || !data[key]) && delete data[key]);

      console.log('Updating url with current state:', data, 'base state:', rootState.route.query);
      router.replace({
        query: {
          //...rootState.route.query,
          ...data
        }
      });
    },

    routerPush (_, arg) {
      router.push(arg)
    },

    routerGo (_, arg) {
      router.go(arg)
    }
  }
};

export default new Vuex.Store({
  state: {
    sourcesInfo: sourcesInfo,
    nav: nav,
    search: search,
    searchExpanded: false,
    currentPageMeta: null,
    //homepage: homepage
  },
  getters: {
  },
  mutations: {
    updateSearchExpanded(state, payload) {
      state.searchExpanded = payload;
    },
    updatePageMeta(state, payload) {
      //console.log('changing page meta to', payload)
      state.currentPageMeta = payload;
    },
  },
  actions: {
  },
  modules: {
    filter: filterStore
  }
});
