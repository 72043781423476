<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      disable-resize-watcher
      temporary
      app
      color="grey lighten-4"
    >
      <v-list-item to="/">
        <v-list-item-content>
          <v-list-item-title class="title">
            <div><img src="/images/logo.svg" alt="Logo VeganFoodValues"  height="50" width="200" ></img></div>
            <strong>VeganFoodValues</strong>.com
          </v-list-item-title>
          <v-list-item-subtitle>
            Discover new vegan recipes
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <template v-for="(item, i) in $store.state.nav.drawer">
          <v-list-item v-if="item.heading" :key="i">
            <v-list-item-content>
              <v-list-item-title class="subtitle">
                {{ item.heading }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-4" />
          <v-list-item v-else :key="i" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey--text">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block to="/recipes">Search Recipes</v-btn>
        </div>
      </template>      
    </v-navigation-drawer>
    <v-app-bar
      app
      color="primary"
      :class="$route.meta.toolbarClass"
      dark
      extended
      extension-height="4"
      elevate-on-scroll
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" aria-label="Show side menu"/>
      
      <router-link to="/" class="pa-0 logo"><img src="/images/logo-white.svg" alt="Logo VeganFoodValues" class="mr-2" height="32" width="50" ></img></router-link>
      <v-toolbar-title v-if="!$store.state.searchExpanded"><v-btn text to="/" exact class="px-0">VeganFoodValues</v-btn></v-toolbar-title>
      <v-spacer/>
      
      <v-text-field
        hide-details
        single-line
        prepend-icon="fa-search"
        placeholder="Search recipes"
        type="search"
        :class="$store.state.searchExpanded || 'hidden-xs-only'"
        ref="omnisearch"
        @keyup.enter="$router.push({ name: 'recipes', query: { query: $event.target.value } })"
        @keyup.esc="$store.commit('updateSearchExpanded', false)"
      ></v-text-field>
      <v-btn v-if="!$store.state.searchExpanded" icon class="d-sm-none" @click="focusSearch" aria-label="Search recipes"><v-icon>fa-search</v-icon></v-btn>
      <!-- <v-btn text to="/recipes">Recipes</v-btn>
      <v-btn text to="/ingredients" class="hidden-sm-and-down">Ingredients</v-btn>
      <v-btn text to="/nutrients" class="hidden-sm-and-down">Nutrients</v-btn> -->
      <ShareButton/> 
      <!-- <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on" aria-label="Show menu">
            <v-icon >fa fa-ellipsis-v</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item  v-for="(item, i) in $store.state.nav.menu" :key="i" :to="item.to" >
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>    -->   
      <v-progress-linear slot="extension"  v-if="isHttpRequestLoading" indeterminate color="yellow" class="ajax-loader ma-0"></v-progress-linear>
    </v-app-bar>
    <!-- <v-progress-linear v-if="isHttpRequestLoading" indeterminate color="yellow darken-2" class="ajax-loader"></v-progress-linear> -->
    <div v-if="$route.meta.contentLayout" id="page-hero" class="container px-0 py-6 py-md-12 white--text py-md-12 container--fluid">
      <div class="container">
        <h1 v-if="!pageImage" class="display-2">{{pageTitle}}</h1>
        <div v-if="pageImage" class="d-flex flex-row flex-sm-row">
          <h1 class="display-2 width-50p align-self-end" :class="{'display-1': $vuetify.breakpoint.xs}">{{pageTitle}}</h1>
          <img class="width-50p illustration" :src="pageImage" :alt="pageTitle" /> 
        </div>
      </div>
    </div>
    <v-content v-if="!$route.meta.noLayout">
      <v-container>
        <v-row>
          <v-col>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <router-view v-if="$route.meta.noLayout"></router-view>
    <v-footer
      color="grey darken-4"
      dark
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col 
          class="green py-4 px-4 text-center white--text"
          cols="12"
        >
          We are in <code>beta</code> phase. See anything strange? Have a comment or tip? We'd <router-link to="/contact" class="white--text">love to hear from you</router-link>!
        </v-col>
        <v-btn
          v-for="(link, i) in $store.state.nav.footer"
          :key="i"
          :to="link.to"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ link.text }}
        </v-btn>
        <v-col 
          class="black py-4 text-center white--text"
          cols="12"
        >
          <img src="/images/logo.svg" class="float-left mx-2" width="100" alt="Logo VeganFoodValues"></img>
          {{ new Date().getFullYear() }} — <strong>Vegan</strong>Food<strong>Values</strong>.com
          <div>
          <router-link to="/privacypolicy" class="white--text">privacy</router-link>
          |
          <router-link to="/cookiepolicy" class="white--text">cookies</router-link>
          |
          <router-link to="/termsofservice" class="white--text">Terms of Service</router-link>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style src="./assets/css/site.css"></style>
<style src="./styles/global.scss" lang="scss"></style>
<style lang="scss">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .ajax-loader.v-progress-linear {
    /*top: 64px;*/
  }
  .v-app-bar a.logo { line-height: 0;}
  .v-toolbar__title .v-btn { text-transform: inherit !important; font-family: 'Lora', serif; line-height: 18px; font-weight: 700;; font-size: 18px; letter-spacing: 0 !important; }
  .v-toolbar__title .v-btn:before { display: none; }
  .v-toolbar__content, .v-toolbar__extension { padding: 0 !important; }
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:last-child { margin-right: 0!important;}
  body header.v-app-bar--is-scrolled {
    background: linear-gradient(to right, var(--v-primary-base), var(--v-gradient-base)) !important; 
  }
</style>

<script>
  import httpStore from "./store/http-store";
  import { EventBus } from '@/plugins/eventbus.js';
  import ShareButton from "@/components/ShareButton.vue";

  export default {
    props: {
      source: String,
    },
    components: {
      ShareButton,
    },
    data: () => ({
      drawer: null,
      titleOverride: null,
      imageOverride: null,
    }),
    mounted() {
      EventBus.$on('page-header-change', data => {
        this.titleOverride = data.title;
        this.imageOverride = data.image;
      });
      window.addEventListener('click', event => {
        // ensure we use the link, in case the click has been received by a subelement
        let { target } = event;
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that do not reference external resources
        if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
        
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const to = url.pathname
          if (window.location.pathname !== to && event.preventDefault) {
            event.preventDefault()
            this.$router.push(to)
          }
        }
      })
    },
    methods: {
      focusSearch() {
        this.$store.commit('updateSearchExpanded', true);
        this.$nextTick(() => this.$refs.omnisearch.$el.getElementsByTagName('input')[0].focus());
      }
    },
    computed: {
      isHttpRequestLoading () {
        return httpStore.getters.loading
      },
      pageTitle() {
        return this.titleOverride || this.$route.meta.title;
      },
      pageImage() {
        return this.imageOverride || (this.$route.meta.illustration ? '/illustrations/'+this.$route.meta.illustration : null);
      },

    }

  }
</script>